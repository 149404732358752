import { Spin } from "antd"
import React, { FC, useEffect, useState } from "react"
import { useGetLinkQuery, useGetMoreSectionTypesQuery, useGetRegionsLazyQuery } from "../../../../../graphql"
import LeftSide from "./components/LeftSide"
import RightSide from "./components/RightSide"
import "./index.less"
import { useAuth } from "../../../../../../src/components/auth"

const ManageBlock: FC<ManageLinkBlockInterface> = ({ selectedLinkId }) => {
  const { region, role } = useAuth()
  const allRegions = ["Asia - Oceania", "Europe - Middle East - Africa", "North America"]
  const regionsOfAssets = role?.toLowerCase().includes('super') ? allRegions : [region.name || '']
  const { data: moreSectionTypes } = useGetMoreSectionTypesQuery({ fetchPolicy: "network-only", variables: { region: regionsOfAssets } })
  const { data: linkData, loading } = selectedLinkId ? useGetLinkQuery({
    fetchPolicy: "network-only",
    variables: {
      id: String(selectedLinkId),
    },
  }) : { data: null, loading: false }
  const link = linkData?.appNewsPost?.data

  const [initialFilters, setInitialFilters] = useState<{ [key: string]: any }>()
  const [filters, setFilters] = useState<{ [key: string]: any }>()
  const types: SelectItems | undefined =
    moreSectionTypes?.appMoreSectionTypes?.data
      ?.filter(item => item.attributes!.name !== "product_matchmaker" && item.attributes!.name !== "events")
      ?.map(item => ({
        value: item.attributes!.name!,
        label: item.attributes!.label!,
        key: String(item.id)
      }))

  useEffect(() => {

    if (types && ((selectedLinkId && link) || !selectedLinkId)) {
      const filters = {
        image: null,
        title: link?.attributes?.title || "",
        urlLink: link?.attributes?.link || "",
        description: link?.attributes?.body || "",
        currentImage: link?.attributes?.image?.data?.attributes?.url || "",
        type: link?.attributes?.more_section_type?.data?.attributes?.name || "",
      }
      setInitialFilters(filters)
      setFilters(filters)
    }
  }, [link, moreSectionTypes])

  const isChangesDisabled = !!(initialFilters?.title && !selectedLinkId)

  return (
    <div className={`block-manage-wrapper ${loading && "center"}`}>
      {!loading && filters && initialFilters && types ? (
        <>
          <LeftSide
            {...{
              link,
              types,
              filters,
              setFilters,
              initialFilters,
              selectedLinkId,
              isChangesDisabled,
              setInitialFilters,
            }}
          />
          <RightSide
            {...{
              link,
              types,
              filters,
              setFilters,
              isChangesDisabled,
            }}
          />
        </>
      ) : (
        <Spin className={"links-manage-block-spin"} />
      )}
    </div>
  )
}

export default ManageBlock
