import { Form as AntForm, Empty, Input, Select, Spin } from "antd"
import React, { FC, useEffect, useState } from "react"
import { CSSTransition, SwitchTransition } from "react-transition-group"
import {
  useCreateOrUpdateUserMutation,
  useGetRegionsLazyQuery,
  useGetRolesLazyQuery,
} from "../../../../../../../../../graphql"
import { BlockIcon, CloseIcon, SaveIcon, SearchIcon, UnblockIcon } from "../../../../../../../../../icons"
import { areAllKeysPopulated, areObjectsEqual, checkFormNotDone, truncate } from "../../../../../../../../../utils"
import Permissions from "../Permissions"
import "./index.less"
const pagination = { limit: 1000, start: 0 }

const Form: FC<RoleManageBlockLeftSideFormInterface> = ({
  user,
  blocked,
  options,
  filters,
  showModal,
  setOptions,
  setFilters,
  selectedUserId,
  initialFilters,
  isChangesDisabled,
  setInitialFilters,
}) => {
  const [createOrUpdateUser] = useCreateOrUpdateUserMutation()

  const [getRegions] = useGetRegionsLazyQuery()
  const [getRoles] = useGetRolesLazyQuery()

  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [isFormReady, setIsFormReady] = useState<boolean>(false)
  const [finishLoading, setFinishLoading] = useState<boolean>(false)
  const [isRegionDisabled, setIsRegionDisabled] = useState<boolean>(false)

  const role = filters?.role.toLowerCase().replace("adminportal ", "")

  const filteredOption = (input: string, option: { label: string; value: string; key?: number }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())

  const getItems = async () => {
    setLoading(true)

    const itemCreator = (item: any) => ({
      value: item?.attributes?.name,
      label: item?.attributes?.name,
      key: item?.id,
    })

    const regionsData = await getRegions()
    const regionsOptions = regionsData?.data?.regions?.data
      .map((item: any) => itemCreator(item))
      .filter((item: any) => item.value)
    regionsOptions?.unshift({ key: "0", value: "All", label: "All" })

    const rolesData = await getRoles({ variables: { sort: "name:asc", pagination } })
    const rolesOptions = rolesData?.data?.usersPermissionsRoles?.data
      .map((item: any) => itemCreator(item))
      .filter((item: any) => item?.value && item.value.toLowerCase().includes("adminportal"))
      .map(item => ({ ...item, label: item.label?.replace("Adminportal ", "") }))

    setOptions({
      ...options,
      roles: rolesOptions,
      regions: regionsOptions,
    })

    setLoading(false)
  }

  const handleValuesChange = (value: string, type: string) => {
    setFilters({
      ...filters,
      [type]: value,
    })
  }

  const validateRegion = () => {
    const switchToAllRegions = () =>
      filters?.region !== "All" &&
      setFilters({
        ...filters,
        region: "All",
      })

    const resetRegion = () =>
      filters?.region &&
      setFilters({
        ...filters,
        region: "",
      })

    switch (role) {
      case "super admin":
        switchToAllRegions()
        setIsRegionDisabled(true)
        break

      case "editor":
        switchToAllRegions()
        setIsRegionDisabled(true)
        break

      default:
        resetRegion()
        setIsRegionDisabled(false)
    }
  }

  const handleFinish = async () => {
    let { name, email, role, region } = filters as any

    try {
      setFinishLoading(true)
      role = role.includes("Adminportal") ? role : "Adminportal " + role
      const response = await createOrUpdateUser({
        variables: {
          input: {
            ...(selectedUserId ? { user_id: selectedUserId } : {}),
            data: {
              name,
              role,
              email: email.trim().toLowerCase(),
              isAppActivated: user?.attributes?.isAppActivated || false,
              region: region !== "All" ? options?.regions?.find(option => option.label == region)?.key : null,
            },
          },
        },
      })

      if (response?.data?.createOrUpdateUser?.success) setInitialFilters(filters)
      setFinishLoading(false)
    } catch (error: any) {
      setFinishLoading(false)
      error!.message && setErrorMessage(truncate(error.message, 100))
    }
  }

  const createSelectElement = (type: string, name: string, options: SelectItems, disabled: boolean) => (
    <AntForm.Item name={name} label={name} required={true}>
      <div className={"input-wrapper"}>
        <Select
          showSearch
          options={role == "admin" ? options?.filter(option => option?.label !== "All") : options}
          disabled={disabled}
          optionFilterProp='children'
          defaultActiveFirstOption={true}
          filterOption={filteredOption as any}
          onChange={data => handleValuesChange(data, type)}
          value={filters?.[type] ? truncate(filters?.[type], 40) : ""}
          notFoundContent={
            loading || !options ? (
              <div className={"no-data"} children={<Spin />} />
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className={"no-data"} />
            )
          }
          suffixIcon={<SearchIcon style={{ opacity: filters?.[type] ? 0 : 1 }} />}
        />
      </div>
    </AntForm.Item>
  )

  const createInputElement = (type: string, label: string) => (
    <AntForm.Item label={label} required={true}>
      <div className={"input-wrapper"}>
        <Input
          disabled={type === "email" && !!initialFilters?.email}
          placeholder={"Type here"}
          value={(filters?.[type] as string) || ""}
          onChange={e => handleValuesChange(e.target.value, type)}
        />
      </div>
    </AntForm.Item>
  )

  useEffect(() => {
    const isDifferentRootValues = !areObjectsEqual(filters!, initialFilters!)
    const isRootValuesValid = areAllKeysPopulated(filters!, [])
    checkFormNotDone({ isDifferentRootValues })
    setIsFormReady(isDifferentRootValues && isRootValuesValid)
    if (errorMessage) setErrorMessage("")
    if (!options?.regions && !options?.roles) getItems()
  }, [filters, initialFilters])

  useEffect(() => {
    validateRegion()
  }, [filters?.role])

  return (
    <>
      <AntForm layout='vertical'>
        <div className={"info-wrapper roles"}>
          <div className={"block"}>
            <div className={"title"}>
              <div>USER</div>
            </div>
            <div className={"inputs-wrapper"}>
              {createInputElement("name", "Name")}
              {createInputElement("email", "Email")}
              {createSelectElement("role", "Role", options.roles!, false)}
              {createSelectElement("region", "Region", options.regions!, isRegionDisabled)}
            </div>
          </div>

          <div className={"error"} style={{ opacity: errorMessage ? 1 : 0 }} children={errorMessage} />

          <div className={"block"}>
            <div className={"title"}>PERMISSIONS</div>
            <Permissions role={role} />
          </div>
        </div>
      </AntForm>

      <button
        type={"submit"}
        onClick={handleFinish}
        className={`save ${!(!isFormReady || isChangesDisabled) && "ready"}`}
        disabled={!isFormReady || isChangesDisabled}
      >
        Save
        {finishLoading ? <Spin /> : <SaveIcon />}
      </button>

      <SwitchTransition mode={"out-in"}>
        <CSSTransition key={JSON.stringify(blocked)} timeout={250} classNames={"fade"} appear={true} unmountOnExit>
          <>
            {selectedUserId ? (
              <button onClick={showModal} className={`${!blocked ? "block-role" : "unblock-role"}`}>
                {!blocked ? "Block User" : "Unblock User"}
                {!blocked ? <BlockIcon /> : <UnblockIcon />}
              </button>
            ) : null}
          </>
        </CSSTransition>
      </SwitchTransition>
    </>
  )
}

export default Form
