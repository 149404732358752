import { Form as AntForm, Empty, Input, Select, Spin } from "antd"
import { FC, useEffect, useState } from "react"
import { CSSTransition, SwitchTransition } from "react-transition-group"
import { useAuth } from "src/components/auth"
import { useCreateOrUpdateSalonMutation, useGetRegionsLazyQuery, useGetUserNamesLazyQuery } from "src/graphql"
import { BlockIcon, SaveIcon, SearchIcon, UnblockIcon } from "src/icons"
import { areAllKeysPopulated, areObjectsEqual, checkFormNotDone, removeSelectDuplicates, truncate } from "src/utils"
import { allItems } from "./countries"
import "./index.less"

const Form: FC<RoleManageBlockLeftSideFormInterface> = ({
  user,
  blocked,
  options,
  filters,
  showModal,
  setOptions,
  setFilters,
  selectedUserId,
  initialFilters,
  isChangesDisabled,
  setInitialFilters,
}) => {
  const { permissions, region } = useAuth()
  const blockPermissions = permissions?.firstMenu
    ?.find(item => item.name == "Team")
    ?.blocks?.find((block: any) => block.name == "Salon")

  const [createOrUpdateSalon] = useCreateOrUpdateSalonMutation()

  const [getRegions] = useGetRegionsLazyQuery()
  const [getOtherLevelUsers] = useGetUserNamesLazyQuery()

  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [isFormReady, setIsFormReady] = useState<boolean>(false)
  const [finishLoading, setFinishLoading] = useState<boolean>(false)

  const filteredOption = (input: string, option: { label: string; value: string; key?: number }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())

  const getRegionOptions = async () => {
    setLoading(true)

    const itemCreator = (item: any) => ({
      value: item?.attributes?.name,
      label: item?.attributes?.name,
      key: item?.id,
    })

    const regionsData = await getRegions()
    let regionsOptions = regionsData?.data?.regions?.data.map((item: any) => itemCreator(item))
    if (region.name) {
      const adminRegion = region!.name!
      regionsOptions = [{ label: adminRegion, value: adminRegion, key: 0 }]
    }

    setOptions({
      ...options,
      region: regionsOptions,
    })

    setLoading(false)
  }

  const handleValuesChange = (value: string | boolean, type: string) => {
    const resetData = (data: { [key: string]: null }) => {
      setFilters({
        ...filters,
        [type]: value,
        ...data,
      })
      setOptions({
        ...options,
        ...data,
      })
    }

    switch (type) {
      case "region":
        {
          resetData({
            dsc: null,
            country: null,
            distributor: null,
            salesManager: null,
          })
        }
        break

      case "distributor":
        {
          resetData({
            dsc: null,
            salesManager: null,
          })
        }
        break

      case "salesManager":
        {
          resetData({
            dsc: null,
          })
        }
        break

      default: {
        setFilters({
          ...filters,
          [type]: value,
        })
      }
    }
  }

  const findId = (type: string) =>
    options?.[type]?.find(item => item.label == (filters as any)[type])?.key ||
    user?.attributes[type == "salesManager" ? "sales_manager" : type]?.data?.id

  const getSelectItems = async (type: string) => {
    if (type == "country") {
      setOptions({
        ...options,
        country: allItems.countries.filter(
          item =>
            item.region ==
            (filters?.region == "Asia - Oceania" ? 0 : filters?.region == "Europe - Middle East - Africa" ? 1 : 2)
        ),
      })
      return
    }

    const createOptions = (item: any[]) => {
      return removeSelectDuplicates(
        item
          .map(user => {
            const userData = user?.attributes
            const distributorData = user?.attributes?.distributor?.data?.attributes
            return {
              label: type !== "distributor" ? userData?.name : distributorData?.name,
              value: type !== "distributor" ? userData?.name : distributorData?.name,
              key: userData?.[type == "salesManager" ? "sales_manager" : type]?.data?.id,
            }
          })
          .concat({
            label: "Unknown",
            value: "Unknown",
            key: "0",
          })
      )
    }

    const distributorId = findId("distributor")
    const salesManagerId = findId("salesManager")

    const createQuery = (type: string) => ({
      variables: {
        pagination: { limit: 10000 },
        filter: {
          role: {
            name: { in: [type] },
          },
          ...(type == "DSC" ? { dsc: { sales_manager: { id: { eq: salesManagerId } } } } : {}),
          ...(type == "Distributor" ? { distributor: { region: { name: { eq: filters!.region } } } } : {}),
          ...(type == "Sales Manager" ? { sales_manager: { distributor: { id: { eq: distributorId } } } } : {}),
        },
      },
    })

    switch (type) {
      case "distributor":
        {
          const {
            data: { usersPermissionsUsers: { data: distributorsData = [] } = {} },
          } = (await getOtherLevelUsers(createQuery("Distributor"))) as any
          setOptions({ ...options, distributor: createOptions(distributorsData) })
        }
        break
      case "salesManager":
        {
          const {
            data: { usersPermissionsUsers: { data: salesManagersData = [] } = {} },
          } = (await getOtherLevelUsers(createQuery("Sales Manager"))) as any
          setOptions({ ...options, salesManager: createOptions(salesManagersData) })
        }
        break
      case "dsc": {
        const {
          data: { usersPermissionsUsers: { data: dscsData = [] } = {} },
        } = (await getOtherLevelUsers(createQuery("DSC"))) as any
        setOptions({ ...options, dsc: createOptions(dscsData) })
      }
    }
  }

  const handleFinish = async () => {
    const {
      name: salonName,
      email,
      region: regionName,
      distributor,
      salesManager,
      dsc,
      country,
      state,
      city,
      insiderPermission,
    } = filters as any

    const dscId = findId("dsc")
    const distributorId = findId("distributor")
    const salesManagerId = findId("salesManager")
    const countryLabel = options?.country?.find(item => item.value == country)?.label || user?.country
    const regionId = options?.region?.find(item => item.label == regionName)?.key || region?.id

    try {
      setFinishLoading(true)
      const response = await createOrUpdateSalon({
        variables: {
          input: {
            city,
            email,
            state,
            salonName,
            isActive: false,
            stylistsAmount: "0",
            country: countryLabel,
            registeredStylists: [],
            insiderPermission: !!insiderPermission,
            region: { id: regionId, name: regionName },
            ...(user?.id ? { salonId: user.id } : {}),
            ...(dsc !== "Unknown" ? { dsc: { name: dsc, id: dscId } } : {}),
            ...(distributor !== "Unknown" ? { distributor: { name: distributor, id: distributorId } } : {}),
            ...(salesManager !== "Unknown" ? { salesManager: { name: salesManager, id: salesManagerId } } : {}),
          },
        },
      })

      if (response?.data?.newSalon) setInitialFilters(filters)
      setFinishLoading(false)
    } catch (error: any) {
      setFinishLoading(false)
      error!.message && setErrorMessage(truncate(error.message, 100))
    }
  }

  const createSelectElement = (type: string, name: string, options: SelectItems, disabled: boolean, getData?: any) => {
    const noIcon =
      type == "status" || type == "role" || (type == "state" && filters?.country?.toLowerCase() != "united states")
    return (
      <AntForm.Item
        name={name}
        label={name}
        required={!noIcon && !(type == "state" && filters?.country?.toLowerCase() != "united states")}
      >
        <div className={"input-wrapper"}>
          <Select
            showSearch
            options={options}
            disabled={disabled}
            optionFilterProp={"children"}
            defaultActiveFirstOption={true}
            filterOption={filteredOption as any}
            onDropdownVisibleChange={() => getData && getData(type)}
            onChange={data => handleValuesChange(data, type)}
            value={type == "role" ? "Salon" : filters?.[type] ? truncate(filters?.[type], 40) : ""}
            notFoundContent={
              loading || !options ? (
                <div className={"no-data"} children={<Spin />} />
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className={"no-data"} />
              )
            }
            suffixIcon={<SearchIcon style={{ opacity: noIcon ? 0 : 1 }} />}
          />
        </div>
      </AntForm.Item>
    )
  }

  const createInputElement = (type: string, label: string) => (
    <AntForm.Item label={label} required={true}>
      <div className={"input-wrapper"}>
        <Input
          disabled={type === "email" && !!initialFilters?.email}
          placeholder={"Type here"}
          value={(filters?.[type] as string) || ""}
          onChange={e => handleValuesChange(e.target.value, type)}
        />
      </div>
    </AntForm.Item>
  )

  useEffect(() => {
    const isDifferentRootValues = !areObjectsEqual(filters!, initialFilters!)
    const isRootValuesValid = areAllKeysPopulated(filters!, [
      ...(filters?.country?.toLowerCase() !== "united states" ? ["state"] : []),
    ])
    checkFormNotDone({ isDifferentRootValues })
    setIsFormReady(isDifferentRootValues && isRootValuesValid)

    if (errorMessage) setErrorMessage("")
    if (!options?.region) {
      getRegionOptions()
    }
  }, [filters, initialFilters])

  return (
    <>
      <AntForm layout={"vertical"}>
        <div className={"info-wrapper salon"}>
          <div className={"block"}>
            <div className={"title"}>
              <div>GENERAL INFORMATION</div>
            </div>
            <div className={"inputs-wrapper"}>
              {createInputElement("name", "Salon Name")}
              {createInputElement("email", "Email")}
            </div>
          </div>

          <div className={"block"}>
            <div className={"title"}>
              <div>ROLE</div>
              <div>APP STATUS</div>
            </div>
            <div className={"inputs-wrapper"}>
              {createSelectElement("role", "Role", [], true)}
              {createSelectElement("status", "App Status", options.status!, true)}
            </div>

            <div className={"switch-holder"}>
              <label className={"switch"}>
                <input
                  disabled={blocked}
                  type={"checkbox"}
                  checked={filters?.insiderPermission}
                  onChange={e => handleValuesChange(e.target.checked, "insiderPermission")}
                />
                <span className={"slider round"} />
              </label>
              Allow this Salon to enter KM INSIDER
            </div>
          </div>

          <div className={"block"}>
            <div className={"title"}>
              <div>LOCATION</div>
            </div>
            <div className={"inputs-wrapper"}>
              {createSelectElement("region", "Region", options.region!, !!region.name)}
              {createSelectElement("country", "Country", options.country!, !filters?.region, getSelectItems)}
              {createSelectElement(
                "state",
                "State",
                options.state!,
                filters?.country?.toLowerCase() !== "united states"
              )}
              {createInputElement("city", "City")}
            </div>
          </div>

          <div className={"block"}>
            <div className={"title"}>
              <div>RELATIONS</div>
            </div>
            <div className={"inputs-wrapper"}>
              {createSelectElement(
                "distributor",
                "Distributor",
                options.distributor!,
                !filters?.region,
                getSelectItems
              )}
              {createSelectElement(
                "salesManager",
                "Sales Manager",
                options.salesManager!,
                !filters?.distributor,
                getSelectItems
              )}
              {createSelectElement("dsc", "DSC", options.dsc!, !filters?.salesManager, getSelectItems)}
            </div>
          </div>

          <div className={"error"} style={{ opacity: errorMessage ? 1 : 0 }} children={errorMessage} />
        </div>
      </AntForm>

      <button
        type={"submit"}
        onClick={handleFinish}
        className={`save ${!(!isFormReady || isChangesDisabled) && "ready"}`}
        disabled={!isFormReady || isChangesDisabled}
      >
        Save
        {finishLoading ? <Spin /> : <SaveIcon />}
      </button>

      <SwitchTransition mode={"out-in"}>
        <CSSTransition
          key={JSON.stringify(blocked || "")}
          timeout={250}
          classNames={"fade"}
          appear={true}
          unmountOnExit
        >
          <>
            {selectedUserId && blockPermissions?.delete ? (
              <button onClick={showModal} className={`${!blocked ? "block-role" : "unblock-role"}`}>
                {!blocked ? "Block User" : "Unblock User"}
                {!blocked ? <BlockIcon /> : <UnblockIcon />}
              </button>
            ) : null}
          </>
        </CSSTransition>
      </SwitchTransition>
    </>
  )
}

export default Form
